<template>
  <div>
    <v-row v-if="loading" dense class="pa-12">
      <v-col cols="12">
        <v-skeleton-loader type="table-heading, table-thead, table-row-divider@7" class="mx-auto"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!loading" dense class="pa-12 pt-3">
      <!-- <v-col cols="12" md="6" xs="2" class="mt-3">
        <span>{{ $t('portfolio.startingDate') }}: 09/03/2019</span>
      </v-col> -->
      <v-col cols="12" md="6" xs="2" class="mt-3">
        <span>{{ $t('portfolio.date') }}: 09/03/2019 - 11/28/2020</span>
      </v-col>
      <v-col cols="12" md="2" xs="2" class="ml-auto">
        <v-select :items="selectList" class="fs-14" item-text="text" item-value="value" clearable v-model="current"
          hide-details dense :label="$t('portfolio.interval')"></v-select>
      </v-col>
      <!-- 30days -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '30days'">
        <h5 class="text-center">{{$t("portfolio.30days")}}</h5>
        <v-data-table dense :headers="Header" :items="result.quasar1Month" hide-default-footer disable-pagination>
          <template v-slot:item.start="{ item }">
            <span class="font-12">{{ item.start }}</span>
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12" :class="item.roe < 0 ? 'red--text' : ''">{{ item.roe.toFixed(4) + "%" }}</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12" :class="item.pl < 0 ? 'red--text' : ''">{{ item.pl.toFixed(4) }}</span>
          </template>
          <template v-slot:item.end="{ item }">
            <span class="font-12">{{ item.end.toFixed(4) }}</span>
          </template>
        </v-data-table>
      </v-col>
      <!-- 60days -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '60days'">
        <h5 class="text-center mb-5">{{$t("portfolio.60days")}}</h5>
        <v-data-table dense :headers="Header" :items="result.quasar2Month" hide-default-footer disable-pagination>
          <template v-slot:item.start="{ item }">
            <span class="font-12">{{ item.start }}</span>
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12" :class="item.roe < 0 ? 'red--text' : ''">{{ item.roe.toFixed(4) + "%" }}</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12" :class="item.pl < 0 ? 'red--text' : ''">{{ item.pl.toFixed(4) }}</span>
          </template>
          <template v-slot:item.end="{ item }">
            <span class="font-12">{{ item.end.toFixed(4) }}</span>
          </template>
        </v-data-table>
      </v-col>
      <!-- 1quarter-->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '1quarter'">
        <h5 class="text-center mb-5">{{$t("portfolio.1quarter")}}</h5>
        <v-data-table dense :headers="Header" :items="result.quasar3Month" hide-default-footer disable-pagination>
          <template v-slot:item.start="{ item }">
            <span class="font-12">{{ item.start }}</span>
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12" :class="item.roe < 0 ? 'red--text' : ''">{{ item.roe.toFixed(4) + "%" }}</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12" :class="item.pl < 0 ? 'red--text' : ''">{{ item.pl.toFixed(4) }}</span>
          </template>
          <template v-slot:item.end="{ item }">
            <span class="font-12">{{ item.end.toFixed(4) }}</span>
          </template>
        </v-data-table>
      </v-col>
      <!-- 6months -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '6months'">
        <h5 class="text-center mb-5">{{$t("portfolio.6months")}}</h5>
        <v-data-table dense :headers="Header" :items="result.quasar6Month" hide-default-footer disable-pagination>
          <template v-slot:item.start="{ item }">
            <span class="font-12">{{ item.start }}</span>
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12" :class="item.roe < 0 ? 'red--text' : ''">{{ item.roe.toFixed(4) + "%" }}</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12" :class="item.pl < 0 ? 'red--text' : ''">{{ item.pl.toFixed(4) }}</span>
          </template>
          <template v-slot:item.end="{ item }">
            <span class="font-12">{{ item.end.toFixed(4) }}</span>
          </template>
        </v-data-table>
      </v-col>
      <!-- 1year -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == '1year'">
        <h5 class="text-center mb-5">{{$t("portfolio.1year")}}</h5>
        <v-data-table dense :headers="Header" :items="result.quasar1Year" hide-default-footer disable-pagination>
          <template v-slot:item.start="{ item }">
            <span class="font-12">{{ item.start }}</span>
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12" :class="item.roe < 0 ? 'red--text' : ''">{{ item.roe.toFixed(4) + "%" }}</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12" :class="item.pl < 0 ? 'red--text' : ''">{{ item.pl.toFixed(4) }}</span>
          </template>
          <template v-slot:item.end="{ item }">
            <span class="font-12">{{ item.end.toFixed(4) }}</span>
          </template>
        </v-data-table>
      </v-col>
      <!-- sinceInception -->
      <v-col cols="12" class="mt-5" v-if="current == null || current == 'sinceInception'">
        <h5 class="text-center mb-5">{{$t("portfolio.sinceInception")}}</h5>
        <v-data-table dense :headers="Header" :items="result.sinceInception" hide-default-footer disable-pagination>
          <template v-slot:item.start="{ item }">
            <span class="font-12">{{ item.start }}</span>
          </template>
          <template v-slot:item.roe="{ item }">
            <span class="font-12" :class="item.roe < 0 ? 'red--text' : ''">{{ item.roe.toFixed(4) + "%" }}</span>
          </template>
          <template v-slot:item.pl="{ item }">
            <span class="font-12" :class="item.pl < 0 ? 'red--text' : ''">{{ item.pl.toFixed(4) }}</span>
          </template>
          <template v-slot:item.end="{ item }">
            <span class="font-12">{{ item.end.toFixed(4) }}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import {mapGetters} from "vuex";
  export default {
    data() {
      return {
        loading:false,
        current: null,
        result:[],
        selectList: [{
            text: this.$t("portfolio.30days"),
            value: "30days"
          },
          {
            text: this.$t("portfolio.60days"),
            value: "60days"
          },
          {
            text: this.$t("portfolio.1quarter"),
            value: "1quarter"
          },
          {
            text: this.$t("portfolio.6months"),
            value: "6months"
          },
          {
            text: this.$t("portfolio.1year"),
            value: "1year"
          },
          {
            text: this.$t("portfolio.sinceInception"),
            value: "sinceInception"
          },
        ],
        Header: [{
            text: this.$t('portfolio.currency'),
            sortable: false,
            width:'10%',
            value:"currency",
          },
          {
            text: this.$t('portfolio.startingValue'),
            sortable: false,
            align: 'end',
            width:'10%',
            value:"start"
          },
          {
            text: this.$t('portfolio.roe'),
            sortable: false,
            align: 'end',
            width:'10%',
            value:"roe"
          },
          {
            text: this.$t('portfolio.pl'),
            sortable: false,
            align: 'end',
            width:'10%',
            value:"pl"
          },
          {
            text: this.$t('portfolio.endingValue'),
            sortable: false,
            align: 'end',
            width:'10%',
            value:"end"
          },
        ],
      }
    },
    computed: {
      ...mapGetters(['responsive']),
      
    },
    watch: {},
    mounted() {
      this.getQuasarFundHistory();
    },
    created() {

    },
    methods: {
      getQuasarFundHistory(){
        this.loading = true;
        let api = config.baseUrl + "/asset/public/getNavHistory";
        this.$http.get(api).then(res=>{
         let data = res.data;
         if(data.code == 200){
           this.result = data.result;
           this.loading = false;
         }else{
           this.loading = false;
         } 
        })
      },
    }
  }
</script>
