<template>
	<div>
        <v-container fluid class="grid-list-xl pt-0 mt-12" v-if="responsive == 'PC'">
            <v-row class="align-center justify-center">
                <v-col cols="12" md="9" class="px-0 mt-3">
                    <v-card flat tile class="pt-5">
                        <h3 class="text-center">{{ $t('portfolio.quasarFundTitle') }}</h3>
                        <v-col cols="12" class="px-0 mt-5 mx-5">
                            <v-row class="mx-5">
                                <v-col cols="12" sm="12" md="12" class="pl-0 pr-11">
                                    <quasar-fund-line></quasar-fund-line>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-tabs class="mt-5" v-model="type" align-with-title :slider-size="5" slider-color="lightred">
                            <v-tab href="#history">{{ $t('portfolio.history') }}</v-tab>
                            <v-tab href="#recent">{{ $t('portfolio.recent') }}</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="type" class="mt-5">
                            <v-tab-item value="history">
                                <quasar-fund-history></quasar-fund-history>
                            </v-tab-item>
                            <v-tab-item value="recent">
                                <quasar-fund-recent></quasar-fund-recent>
                            </v-tab-item>
                        </v-tabs-items>
                        <!-- <quasar-fund></quasar-fund> -->
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid class="flex-b grid-list-xl mt-12 pb-0 px-0 pt-0" v-if="responsive == 'mobile'">
            <signal-mobile-change></signal-mobile-change>
            <v-row class="align-center justify-center mx-4">
                <v-col cols="12" md="12" class="px-0 mt-3">
                    <v-card flat tile class="pt-5">
                        <h3 class="text-center">{{ $t('portfolio.quasarFundTitle') }}</h3>
                        <v-col cols="12" class="mt-5">
                            <v-row class="mx-5">
                                <v-col cols="12" sm="12" md="12" class="pl-0">
                                    <quasar-fund-line></quasar-fund-line>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-tabs class="mt-5" v-model="type" align-with-title :slider-size="5" slider-color="lightred">
                            <v-tab href="#history">{{ $t('portfolio.history') }}</v-tab>
                            <v-tab href="#recent">{{ $t('portfolio.recent') }}</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="type">
                            <v-tab-item value="history">
                                <quasar-fund-history></quasar-fund-history>
                            </v-tab-item>
                            <v-tab-item value="recent">
                                <quasar-fund-recent></quasar-fund-recent>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- <mobile-btn></mobile-btn>  -->
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue';
    import config from '@/config.js';
    import QuasarFund from '@/components/performance/QuasarFund';
    import QuasarFundHistory from '@/components/performance/QuasarFundHistory';
    import QuasarFundRecent from '@/components/performance/QuasarFundRecent';
    import MobileBtn from "@/components/common/MobileBtn";
    import signalMobileChange from "@/components/signal/SignalMobileChange";
    import QuasarFundLine from "@/highcharts/QuasarFundLine";

    import { mapGetters } from "vuex";
    export default {
        data(){
            return{
                type:"history",
            }
        },
        components:{
            QuasarFundHistory, QuasarFundRecent,MobileBtn,signalMobileChange,QuasarFund,QuasarFundLine
        },
        created(){
            this.isLogin();
        },
        mounted(){

        },
        computed: {
            ...mapGetters(["responsive","user"])
        },
        watch:{

        },
        methods: {
            // 是否登录
            isLogin() {
                // if (!this.user.token) {
                //     this.$router.push({ name: "signIn" });
                //     this.$store.dispatch("snackbarMessageHandler", this.$t("signals.signalSnackbarMessage"));
                // }else{
                //     this.$store.commit('signalPageHandler', this.$t("menu.performance"));
                //     this.$store.dispatch("mobileBtnPageHandler",1);
                // }
                this.$store.commit('signalPageHandler', this.$t("menu.quasarFund"));
                this.$store.dispatch("mobileBtnPageHandler",1);
            },
        },
    }
</script>
