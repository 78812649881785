<template>
<div>
    <div class="font-4x fw-bold" v-if="responsive == 'PC'">
        <v-row>
            <v-col cols="6">
                <div>{{ option.series[0].data.length == 0 ? '--' : '$' + option.series[0].data[option.series[0].data.length - 1].toFixed(4) }}</div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6">
                <span class="d-flex flex-row-reverse mr-2" :class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')">{{ performance == 0 ? '' : (performance > 0 ? ('+' + performance.toFixed(2) + '%') : (performance.toFixed(2) + '%')) }}</span>
            </v-col>
        </v-row>
    </div>
    <div class="mb-3 font-3x fw-bold" v-if="responsive == 'mobile' || responsive == 'pad'">
        <div v-if="option.series[0].data.length > 0">{{ '$' + option.series[0].data[option.series[0].data.length - 1].toFixed(4) }}</div>
        <div v-if="option.series[0].data.length == 0">--</div>
        <div :class="performance == 0 ? '' : (performance > 0 ? 'green--text' : 'red--text')">{{ performance == 0 ? '--' : (performance > 0 ? ('+' + performance.toFixed(2) + '%') : (performance.toFixed(2) + '%')) }}</div>
    </div>
    <div class="mb-3 fs-12" v-if="responsive == 'PC'">
        <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
        <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
        <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
        <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
        <span @click="day=0" class="pointer ml-1 mr-10" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
        <span class="grey--text">{{ $t('signals.interval') }}</span>
        <span class="ml-2 pointer" :class="[interval.value == 'day' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[0]">1D</span>
        <span class="ml-2 pointer" :class="[interval.value == 'week' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[1]">1W</span>
        <span class="ml-2 pointer" :class="[interval.value == 'month' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[2]">1M</span>
        <div class="grey--text">{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</div>
    </div>
    <div class="mb-3 fs-12" v-if="responsive == 'mobile'|| responsive == 'pad'">
        <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
        <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
        <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
        <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
        <span @click="day=0" class="pointer ml-1 mr-1" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
        <div class="float-right mr-3">
            <span class="grey--text">{{ $t('signals.interval') }}</span>
            <span class="ml-2 pointer" :class="[interval.value == 'day' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[0]">1D</span>
            <span class="ml-2 pointer" :class="[interval.value == 'week' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[1]">1W</span>
            <span class="ml-2 pointer" :class="[interval.value == 'month' ? 'lineblue--text ' : 'grey--text']" @click="interval = intervalList[2]">1M</span>
        </div>
        <div class="grey--text">{{$t('highcharts.assetsTrendLineChart.mouseDragToZoom')}}</div>
    </div>
    <div style="height: 360px" :id="id" v-if="responsive == 'PC'"></div>
    <div style="height: 290px" :id="id" v-if="responsive == 'mobile' || responsive == 'pad'"></div>
</div>
</template>

<style>
svg.highcharts-root {
    font-family: "Noto Sans SC", sans-serif !important;
}

.test tspan:active {
    text: expression(target="_blank");
}
</style>

<script>
import config from '@/config.js';
import Highcharts from 'highcharts/highstock';
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            day: 90,
            intervalList: [
                { text: '1D', value: 'day' },
                { text: '1W', value: 'week' },
                { text: '1M', value: 'month' },
            ],
            interval: { text: '1W', value: 'week' },
            id: 'quasarFundNavLineChart',
            quasarNav: null,
            chart: null,
            loading: false,
            performance:0,
            option: {
                // 图表类型
                chart: {
                    backgroundColor: this.darkMode ? 'dark' : '', 
                    renderTo: 'quasarFundNavLineChart',
                    type: 'line',
                    // x方向和y方向都可以用鼠标拖拽放大
                    zoomType: 'xy'
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#9E9E9E'
                    },
                },
                subtitle: {
                    text: '',
                    align: 'left',
                    style: {
                        color: '#999999'
                    },
                },
                // x轴
                xAxis: {
                    categories: [],
                    lineWidth: 1,
                    tickLength: 0,
                    tickWidth: 1,
                    tickInterval: 10,
                    tickPosition: 'inside',
                    type: 'datetime',
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S',
                        second: '%m/%d/%Y %H:%M:%S',
                        minute: '%m/%d/%Y %H:%M',
                        hour: '%m/%d/%Y %H:%M',
                        day: '%m/%d',
                        week: '%m/%d',
                        month: '%m/%Y',
                        year: '%Y'
                    },
                    labels: {
                        enabled: false
                    }
                },
                // y轴
                yAxis: {
                    title: {
                        text: null
                    },
                    visible: true,
                    // 横线宽度设置为0表示不显示横线
                    gridLineWidth: 0,
                    opposite: true,
                    offset: 20,
                    labels: {
                        align: "left",
                        x: -10,
                        formatter: function () {
                            return this.value.toFixed(2);
                        }
                    },
                },
                // 鼠标悬浮提示框
                tooltip: {
                    valueDecimals: 4,
                    valuePrefix: '',
                    valueSuffix: '',
                    followTouchMove: true,
                    dateTimeLabelFormats: {
                        millisecond: '%m/%d/%Y %H:%M:%S UTC',
                        second: '%m/%d/%Y %H:%M:%S UTC',
                        minute: '%m/%d/%Y %H:%M UTC',
                        hour: '%m/%d/%Y %H:%M UTC',
                        day: '%m/%d/%Y %H:%M UTC',
                        week: '%m/%d %H:%M UTC',
                        month: '%m/%Y',
                        year: '%Y'
                    }
                },
                // 数据
                series: [{
                    // type: 'spline',
                    name: this.$t('highcharts.navLineChart.iobQuasarNav'),
                    data: [],
                    color: {
                                linearGradient: {
                                  x1: 0,
                                  x2: 0,
                                  y1: 0,
                                  y2: 1
                                },
                                stops: [
                                  [0, '#4CAF50'],
                                  [1, '#FEE108']
                                ]
                              },
                    lineWidth: 3
                }],
                noData: {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030'
                    }
                },
                // 图例导航
                legend: {
                    enabled: false,
                    align: 'left',
                    itemStyle: {
                        color: '#999999'
                    },
                    itemHoverStyle: {
                        color: '#999999'
                    },
                    itemHiddenStyle: {
                        color: '#606063'
                    },
                    title: {
                        style: {
                            color: '#C0C0C0',
                            fontFamily: '\'Noto Sans SC\', sans-serif'
                        }
                    }
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: 'black',
                        theme: {
                            fill: '#EEEEEE',
                        },
                    }
                },
                // 图形参数
                plotOptions: {
                    line: {
                        lineWidth: 2,
                        cursor: 'pointer',
                        marker: {
                            enabled: false
                        }
                    }
                },
                // 版权信息
                credits: {
                    enabled: false,
                    href: config.fund,
                    text: 'View on iobots.pro',
                    className: 'a',
                    position: {
                        x: -12,
                        y: -5
                    },
                    style: {
                        fontSize: '12px',
                        color: 'white',
                        target: '_black'
                    }
                },
                exporting: {
                    enabled: true,
                    filename: 'iob-Quasar-NAV',
                    buttons: {
                        contextButton: {
                            align: "left",
                            verticalAlign: "top",
                            x: -10,
                            y: 0
                        }
                    }
                },
                loading: {
                    style: {
                        backgroundColor: 'silver'
                    },
                    labelStyle: {
                        color: 'white',
                        fontSize: '36px'
                    }
                }
            },
        }
    },
    props: {

    },
    watch: {
        day(val) {
            this.getAssetsTrend();
        },
        'interval.value'(newVal, oldVal){
            this.getAssetsTrend();
        },
        // 监听加载中
        loading(newVal) {
            if (this.chart) {
                if (newVal) {
                    this.chart.showLoading();
                } else {
                    this.chart.hideLoading();
                }
            }
        },
    },
    created() {
        if(this.responsive == 'mobile'){
            this.option.xAxis.labels.enabled = false;
            this.option.xAxis.tickLength = 0;
        }
    },
    mounted() {
        this.getAssetsTrend();
        Highcharts.setOptions({
            lang: {
                noData: "",
                contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                printChart: this.$t('highcharts.printChart'),
                resetZoom: this.$t('highcharts.resetZoom'),
                resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                downloadPNG: this.$t('highcharts.downloadPNG'),
                downloadJPEG: this.$t('highcharts.downloadJPEG'),
                downloadPDF: this.$t('highcharts.downloadPDF'),
                downloadSVG: this.$t('highcharts.downloadSVG')
            }
        });
        this.option.credits.style.fontSize = this.responsive == 'mobile' ? '10px' : '12px';
        this.renderChart();
    },
    computed: {
        ...mapGetters(['responsive','darkMode'])
    },
    components: {

    },
    methods: {
        getAssetsTrend() {
            this.loading = true;
            // this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
            // this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#303030':'#EEEEEE';
            this.option.series[0].data = [];
            this.performance = 0;
            // let api = config.baseUrl + "/asset/public/getZeroNav?day=" + this.day + "&interval=" + this.interval.value;
            let api = config.baseUrl + "/nav/public/quasar?day=" + this.day + "&interval=" + this.interval.value;
            this.$http.get(api).then(res => {
                let data = res.data;
                if (data.code == 200) {
                    this.quasarNav = data.result;
                    this.option.series[0].data = data.result.navInUSD;
                    // this.option.xAxis.categories = data.result.date;
					let temp = [];
					for(let i=0; i<data.result.date.length; i++){
						let element = data.result.date[i];
						temp.push(this.CommonJS.formatBritishDate(element));		 
					}
					this.option.xAxis.categories = temp;
                    this.performance = data.result.roe * 100;
                    this.loading = false;
                    // this.getPerformance();
                    // 重新渲染图表
                    this.renderChart();
                } else {
                    this.loading = false;
                    this.noData();
                }
            })
        },
        formatDateFunction(timestamp) {
            if (timestamp == 0) {
                return '--';
            } else {
                if ("localTime" == localStorage.getItem("iobots-time")) {
                    return formatDate(timestamp);
                } else {
                    return formatUTCDate(timestamp)
                }
            }
        },
        getPerformance(){
            var first = this.option.series[0].data[0];
            var last = this.option.series[0].data[this.option.series[0].data.length-1];
            this.performance = (last / first - 1) * 100;
        },
        // 渲染图表
        renderChart() {
            this.chart = Highcharts.chart(this.id, this.option);
        },
        noData() {
            Highcharts.setOptions({
                lang: {
                    noData: this.$t('highcharts.noData'),
                    contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
                    printChart: this.$t('highcharts.printChart'),
                    resetZoom: this.$t('highcharts.resetZoom'),
                    resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
                    downloadPNG: this.$t('highcharts.downloadPNG'),
                    downloadJPEG: this.$t('highcharts.downloadJPEG'),
                    downloadPDF: this.$t('highcharts.downloadPDF'),
                    downloadSVG: this.$t('highcharts.downloadSVG')
                }
            });
            this.renderChart();
        },
    }
}
</script>
