<template>
  <div>
      <v-data-table :headers="Header" hide-default-footer disable-pagination class="elevation-1 mb-5">
          <template v-slot:body="">
              <tbody>
                  
              </tbody>
          </template>
      </v-data-table>
  </div>
</template>

<script>
  import Vue from 'vue';
  import config from '@/config.js';
  import {mapGetters} from "vuex";
  // 引入导出Excel表格依赖
  import FileSaver from "file-saver";
  import XLSX from "xlsx";
import Template from '@/template/Template.vue';

  export default {
  components: { Template },
    data() {
      return {
        loading:false,
        current: null,
        result:[],
        Header: [{
            text: this.$t('portfolio.currency'),
            sortable: false,
            width:'10%',
            value:"currency"
          },
          {
            text: this.$t('portfolio.current'),
            sortable: false,
            align: 'end',
            width:'10%',
            value:"current"
          },
          {
            text: this.$t('portfolio.roe'),
            sortable: false,
            align: 'end',
            width:'10%',
            value:"roe"
          },
          {
            text: this.$t('portfolio.pl'),
            sortable: false,
            align: 'end',
            width:'10%',
            value:"pl"
          },
          {
            text: this.$t('portfolio.startingValue'),
            sortable: false,
            align: 'end',
            width:'10%',
            value:"start"
          },
        ],
      }
    },
    computed: {
      ...mapGetters(['user', 'responsive']),
      
    },
    watch: {},
    mounted() {
      this.getQuasarFundHistory();
    },
    created() {

    },
    methods: {
      getQuasarFundHistory(){
        this.loading = true;
        let api = config.baseUrl + "/asset/public/getNavRecent";
        this.$http.get(api).then(res=>{
         let data = res.data;
         if(data.code == 200){
           this.result = data.result;
           this.loading = false;
         }else{
           this.loading = false;
         } 
        })
      },
    }
  }
</script>
