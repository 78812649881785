<template>
	<div>

	</div>
</template>
<script>
    import config from '@/config.js';
    export default {
        data(){
            return {

            }
        },
        created(){

        },
        mounted(){

        },
        computed: {

        },
        watch:{

        },
        methods: {

        },
    }
</script>
<style>

</style>